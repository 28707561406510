// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-app-js": () => import("/codebuild/output/src769596612/src/vowtch-registration-portal/src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src769596612/src/vowtch-registration-portal/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import("/codebuild/output/src769596612/src/vowtch-registration-portal/.cache/data.json")

