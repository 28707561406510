import React from 'react';

import { createStore as reduxCreateStore } from "redux"

import config from '../utils/config';

const executeIfFunction = actionFunction =>
  actionFunction instanceof Function ? actionFunction() : actionFunction

const initialState = {
  alternateCardInnerWidgetInnerWidgetShown: true,
  alternateCardInnerWidgetShown: true,
  alternateCardShown: false,
  alternateFormItemShown: true,
  alternateMenuButtonShown: false,
  alternateMenuShown: false,
  alternateThirdPartyAlternateMenuAlternateShown: false,
  alternateThirdPartyAlternateMenuFilter: false,
  alternateThirdPartyAlternateMenuShown: false,
  alternateThirdPartyMenuShown: false,
  alternateThirdPartyThirdPartyMenuShown: false,
  carouselAutoplay: false,
  carouselContent: config.defaultThirdPartyParameters[config.defaultThirdPartyThirdPartySecondaryField],
  carouselProgress: 0,
  classieContainer: React.createRef(),
  classieContentBadgeText: [],
  data: [],
  drawerOpened: false,
  formMenuShown: false,
  fifthPartyMenuShown: false,
  fourthPartyMenuShown: false,
  menu: config.menu,
  menuShown: true,
  secondaryMenu: false,
  secondaryStaticData: [],
  sixthPartyMenuShown: false,
  staticData: [],
  step: 1,
  subject: false,
  tagDrawerOpened: false,
  tagMenuShown: true,
  thirdPartyMenuShown: false,
  thirdPartyStaticData: []
};

const switchcase = cases => defaultCase => key =>
  cases.hasOwnProperty(key) ? cases[key] : defaultCase

const switchcaseFunction = cases => defaultCase => key =>
  executeIfFunction(switchcase(cases)(defaultCase)(key))

const reducer = (state, action) =>
  switchcaseFunction({
    "filterAlternateThirdPartyAlternateMenu":
      () =>
        Object.assign({}, state, {
          alternateThirdPartyAlternateMenuFilter: action.payload
        })
    ,
    "onChangeCarouselAutoplay":
      () =>
        Object.assign({}, state, {
          carouselAutoplay: !state.carouselAutoplay
        })
    ,
    "onChangeCarouselContent":
      () =>
        Object.assign({}, state, {
          carouselContent: action.payload
        })
    ,
    "onChangeCarouselContentLevel":
      () =>
        Object.assign({}, state, {
          carouselContentLevel: action.payload
        })
    ,
    "onChangeClassieContentBadgeText":
      () =>
        Object.assign({}, state, {
          classieContentBadgeText: action.payload
        })
    ,
    "onChangeDrawer":
      () =>
        Object.assign({}, state, {
          drawerOpened: action.payload
        })
    ,
    "onChangeField":
      () =>
        Object.assign({}, state, {
          fieldValues: {...state.fieldValues, ...action.payload}
        })
    ,
    "onChangeForm":
      () =>
        Object.assign({}, state, {
          form: action.payload
        })
    ,
    "onChangeFormDrawer":
      () =>
        Object.assign({}, state, {
          formDrawerOpened: action.payload
        })
    ,
    "onChangeLoader":
      () =>
        Object.assign({}, state, {
          loader: action.payload
        })
    ,
    "onChangeMenu":
      () =>
        Object.assign({}, state, {
          menu:
            Array.isArray(action.payload) ?
              action.payload.slice().shift()
              :
              action.payload
          ,
          menuModified:
            state.classieContentBadgeText.length ?
              state.menu.items &&
              state.menu.items.length ?
                state.menu
                :
                state.menuModified
              :
              state.menu
          ,
          secondaryMenu:
            Array.isArray(action.payload) ?
              action.payload.slice().shift()
              :
              state.secondaryMenu
        })
    ,
    "onChangeStep":
      () =>
        Object.assign({}, state, {
          step: action.payload
        })
    ,
    "onChangeSubject":
      () =>
        Object.assign({}, state, {
          fieldValues: {},
          formValues: {},
          step: state.step / state.step,
          subject: action.payload
        })
    ,
    "onChangeTagDrawer":
      () =>
        Object.assign({}, state, {
          tagDrawerOpened: action.payload
        })
    ,
    "onChangeTags":
      () =>
        Object.assign({}, state, {
          secondaryStaticData:
            Array.isArray(state.secondaryStaticData) &&
            !state.secondaryStaticData.length ?
              action.payload
              :
              Array.isArray(state.thirdPartyStaticData) ?
                state.thirdPartyStaticData
                :
                Object.assign(
                  {},
                  ...state.thirdPartyStaticData
                  ,
                  {
                    thirdPartyStaticData: state.thirdPartyStaticData.thirdPartyStaticData
                  }
                )
          ,
          thirdPartyStaticData: action.payload
        })
    ,
    "onSubmit":
      () =>
        Object.assign({}, state, {
          formValues: action.payload
        })
    ,
    "openDrawer":
      () =>
        Object.assign({}, state, {
          drawerOpened: !state.drawerOpened
        })
    ,
    "openDrawerFifthPartyMenu":
      () =>
        Object.assign({}, state, {
          drawerOpened: !state.drawerOpened,
          fifthPartyMenuShown: !state.fifthPartyMenuShown
        })
        ,
    "openDrawerSixthPartyMenu":
      () =>
        Object.assign({}, state, {
          drawerOpened: !state.drawerOpened,
          sixthPartyMenuShown: !state.sixthPartyMenuShown
        })
    ,
    "openFormDrawer":
      () =>
        Object.assign({}, state, {
          formDrawerOpened: !state.formDrawerOpened
        })
    ,
    "openFormDrawerForm":
      () =>
        Array.isArray(action.payload) ?
          Object.assign({}, state, {
            form: action.payload.slice().shift(),
            formDrawerOpened: !state.formDrawerOpened,
            formField: action.payload.reverse().slice().shift()
          })
          :
          Object.assign({}, state, {
            form: action.payload,
            formDrawerOpened: !state.formDrawerOpened
          })
    ,
    "openTagDrawer":
      () =>
        Object.assign({}, state, {
          formField: action.payload,
          tagDrawerOpened: !state.tagDrawerOpened,
          secondaryStaticData:
              action.payload == state.formField ?
                state.secondaryStaticData
                :
                Array()
          ,
          thirdPartyStaticData:
              action.payload == state.formField ?
                state.secondaryStaticData
                :
                Array()
        })
    ,
    'receiveData':
      () =>
        Object.assign({}, state, {
          data: action.payload,
          stateModified: !state.stateModified
        })
    ,
    'receiveDataAlternate':
      () =>
        Object.assign({}, state, {
          data: [...state.data, ...action.payload],
          stateModified: !state.stateModified
        })
    ,
    "receiveFormValues":
      () =>
        Object.assign({}, state, {
          formValues: Object.assign(
            {},
            state.formValues,
            action.payload
          )
          ,
          stateModified:
            state.formValues &&
            state.formValues[config.text.innerWidgetInnerWidgetFormValueKey] != action.payload[config.text.innerWidgetInnerWidgetFormValueKey]
        })
    ,
    'receiveStaticData':
      (staticDataField) =>
        staticDataField ?
          Object.assign({}, state, {
            staticData: Object.assign({}, state.staticData, {
              staticDataField: action.payload
            })
          })
          :
          Object.assign({}, state, {
            staticData: action.payload
          })
    ,
    'resetData':
      () =>
        action.payload ?
          action.payload.data ?
            Object.assign({}, state, {
              data: Array(),
            })
            :
            action.payload.secondaryStaticData ?
              Object.assign({}, state, {
                secondaryStaticData: Array(),
                thirdPartyStaticData: Array()
              })
              :
              action.payload.staticData ?
                Object.assign({}, state, {
                  staticData: Array(),
                })
                :
                action.payload.subject ?
                  Object.assign({}, state, {
                    subject: {}
                  })
                  :
                  action.payload.thirdPartyStaticData ?
                    Object.assign({}, state, {
                      thirdPartyStaticData:  Array()
                    })
                    :
                    Object.assign({}, state, {
                      data: Array(),
                      secondaryStaticData: Array(),
                      staticData: Array(),
                      subject: {},
                      thirdPartyStaticData: Array()
                    })
          :
          Object.assign({}, state, {
            data: Array(),
            secondaryStaticData: Array(),
            staticData: Array(),
            subject: {},
            thirdPartyStaticData: Array()
          })

    ,
    "setAnimationCarouselProgress":
      () =>
        Object.assign({}, state, {
          animationCarouselProgress: action.payload
        })
    ,
    "showAlternateCard":
      () =>
        Object.assign({}, state, {
          alternateCardShown: !state.alternateCardShown
        })
    ,
    "showAlternateCardInnerWidgetInnerWidget":
      () =>
        Object.assign({}, state, {
          alternateCardInnerWidgetInnerWidgetShown: !state.alternateCardInnerWidgetInnerWidgetShown,
          data: []
        })
    ,
    "showAlternateCardInnerWidget":
      () =>
        Object.assign({}, state, {
          alternateCardInnerWidgetShown: !state.alternateCardInnerWidgetShown
        })
    ,
    "showAlternateMenu":
      () =>
        Object.assign({}, state, {
          alternateMenuShown: !state.alternateMenuShown
        })
    ,
    "showAlternateMenuButton":
      () =>
        Object.assign({}, state, {
          alternateMenuButtonShown: !state.alternateMenuButtonShown
        })
    ,
    "showAlternateThirdPartyAlternateMenu":
      () =>
        Object.assign({}, state, {
          alternateThirdPartyAlternateMenuShown: !state.alternateThirdPartyAlternateMenuShown
        })
    ,
    "showAlternateThirdPartyAlternateMenuAlternate":
      () =>
        Object.assign({}, state, {
          alternateThirdPartyAlternateMenuAlternateShown: !state.alternateThirdPartyAlternateMenuAlternateShown
        })
    ,
    "showAlternateThirdPartyMenu":
      () =>
        Object.assign({}, state, {
          alternateThirdPartyMenuShown: !state.alternateThirdPartyMenuShown,
          subject: action.payload
        })
    ,
    "showAlternateThirdPartyThirdPartyMenu":
      () =>
        Object.assign({}, state, {
          alternateThirdPartyThirdPartyMenuShown: !state.alternateThirdPartyThirdPartyMenuShown,
          subject: action.payload
        })
    ,
    "showAlternateThirdPartyMenuAlternate":
      () =>
        Object.assign({}, state, {
          thirdPartyMenuShown: !state.thirdPartyMenuShown
        })
    ,
    "showFormMenu":
      () =>
        Object.assign({}, state, {
          formMenuShown: !state.formMenuShown
        })
    ,
    "showFifthPartyMenu":
      () =>
        Object.assign({}, state, {
          fifthPartyMenuShown: !state.fifthPartyMenuShown
        })
    ,
    "showFourthPartyMenu":
      () =>
        Object.assign({}, state, {
          fourthPartyMenuShown: !state.fourthPartyMenuShown
        })
    ,
    "showMenu":
      () =>
        Object.assign({}, state, {
          menuShown: !state.menuShown
        })
    ,
    "showThirdPartyMenu":
      () =>
        Object.assign({}, state, {
          thirdPartyMenuShown: !state.thirdPartyMenuShown
        })
    ,
    "showThirdPartyMenuFormDrawer":
      () =>
        Object.assign({}, state, {
          alternateThirdPartyMenuShown:
            state.alternateThirdPartyMenuShown ?
              state.alternateThirdPartyMenuShown
              :
              !state.alternateThirdPartyMenuShown
          ,
          alternateThirdPartyThirdPartyMenuShown:
            state.alternateThirdPartyThirdPartyMenuShown ?
              !state.alternateThirdPartyThirdPartyMenuShown
              :
              state.alternateThirdPartyThirdPartyMenuShown
          ,
          drawerOpened:
            state.formMenuShown ?
              state.drawerOpened
              :
              !state.drawerOpened
          ,
          formDrawerOpened:
            state.formMenuShown ?
              state.formDrawerOpened
              :
              !state.formDrawerOpened
          ,
          subject:
            state.subject ?
              state.subject
              :
              action.payload
          ,
          thirdPartyMenuShown: !state.thirdPartyMenuShown
        })
  })(state)(action.type);

const createStore = () => reduxCreateStore(reducer, initialState);

export default createStore
