module.exports = {
  customScroll: {
    height: "calc(100vh - 22em)"
  },
  carousel: {
    animation:  [
      {
       	duration: 1000,
        marginLeft: 700,
        onAnimationStart: true
      },
      {
       	duration: 3000,
        marginLeft: 700
      },
      {
       	duration: 2000,
        marginLeft: 700
      },
      {
       	duration: 1000,
        marginLeft: 0,
        onAnimationComplete: true
      }
    ],
    animationSecondary:  [
    ],
    animationThirdParty:  [
      {
       	duration: 0,
        opacity: 1
      },
      {
        duration: 3000,
        opacity: 1
      },
      {
       	duration: 3000,
        opacity: 1
      }
    ],
    animationFourthParty:  [
      {
       	duration: 0,
        scale: 0
      },
      /*{
       	duration: 1000,
        scale: 0
      },*/
      {
       	duration: 1000,
        scale: 0
      },
      {
       	duration: 500,
        scale: 1
      }
    ],
    animationFifthParty:  [
      {
       	duration: 2000,
        marginLeft: 0
      },
      {
       	duration: 1500,
        marginLeft: 0
      },
      {
       	duration: 1500,
        marginLeft: 0
      },
      {
       	duration: 2000,
        marginLeft: 0
      }
    ],
    queueAnimation: {
      delay: 1000,
      duration: 1500,
      interval: [500, 1000]
    },
    textyAnimation: {
      delay: 1500,
      mode: "smooth",
      type: "left"
    },
    menu: {
      buttons: [
        {
          href: "",
          title: "Login",
        },
        {
          href: "",
          title: "Sign Up",
        }
      ],
      header: [
        {
          menuShown: true,
          title: "Menu"
        }
      ],
      headerAlternate: [
        {
          menuShown: true,
          title: "Menu"
        }
      ],
      headerThirdParty: [
        {
          menuShown: true,
          title: "Menu"
        }
      ],
      items: [
        {
          hasAvatar: true,
          title: "Choose Playlist"
        },
        {
          title: "Latest News",
          topic: "5d135ff8fb6fc00e79b09c99"
        },
        {
          title: "Product News",
          topic: "5d135ff8fb6fc00e79b09b00"
        },
        {
          title: "Commercial Offers",
          topic: "5d135ff8fb6fc00e79b09c99"
        }
      ],
      placement: "topCenter"
    },
    progressWidth: 32,
    progressInterval: 1000,
    progressMaximumPercent: 99,
    progressPercent: 12,
    speed: {
      pause: 500,
      play: 2000
    }
  },
  dateFormat: "MMM Do YY",
  datatransfomation: {
    separator: "."
  },
  defaulCcomercial: {
    avatar: "https://cdn.filestackcontent.com/NwSC3I4tS0CoLxhFoxco",
    description: "Go to the Vowtch Global Directory",
    title: "Discover More Communities..."
  },
  defaultDuration: 5000,
  defaultLimit: {
    limit: 70,
  },
  defaultSecondaryLimit: {
    limit: 10,
    skip: 10
  },
  defaultParameters: {
    order_by: "-_id"
  },
  defaultParametersAlternate: {
    order_by: "-_id",
    productType: "franchised"
  },
  defaultSecondaryField: "connections",
  defaultSecondaryParameters: {
    _id: "5e9b57acb0408400087c15d8",
    order_by: "-_id"
  },
  defaultThirdPartyField: "listing",
  defaultThirdPartyParameters: {
    // class_name: "Post",
    currentNetwork: "5d135ff8fb6fc00e79b09f02",
    order_by: "-_id"
  },
  defaultThirdPartyParametersAlternate: {
    // class_name: "Article",
    currentNetwork: "5d135ff8fb6fc00e79b09f01",
    order_by: "-_id"
  },
  defaultThirdPartySecondaryField: "name",
  defaultThirdPartySecondaryParameters: {
    name: "Regions",
    order_by: "-_id"
  },
  defaultThirdPartyThirdPartyField: "content",
  defaultThirdPartyThirdPartyParameters: {
    order_by: "-_id"
  },
  defaultThirdPartyThirdPartySecondaryField: "currentNetwork",
  defaultThirdPartyThirdPartySecondaryFieldAlternate: "_id",
  defaultThirdPartyThirdPartySecondaryFieldThirdParty: "className",
  defaultThirdPartyThirdPartyThirdPartyField: "group",
  defaultThirdPartyThirdPartyThirdPartySecondaryField: "image",
  defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternate: "Post",
  defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateSecondary: "Article",
  defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdParty: "class_name",
  defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternate: "skip",
  defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateSecondary: "id",
  defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateThirdParty: "link",
  defaultFifthPartyField: "conversation",
  defaultFifthPartyFieldAlternate: "incomingRequests",
  defaultFifthPartyFieldThirdParty: "outgoingRequests",
  defaultFormField: "background",
  defaultFormFieldAlternate: "distributionoptionsTag",
  drawerWidth: 405,
  entity: {
    group: {
      types: [
        {
          current: true,
          items: [
            {
              avatar: "https://cdn.filepicker.io/api/file/vQU0MwaSM66z8vyqRM03",
              description: "Entertainment",
              subDescription: "2355 Members",
              subTitle: "Public",
              title: "Conservative Action Network",
              titleInitial: "H"
            }
          ],
          title: "Present Network"
        },
        {
          items: [
            {
              avatar: "https://cdn.filepicker.io/api/file/tcKEoqoXTuKVFGMREPHs",
              description: "Friends",
              title: "All My Peeps"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/qNB5LAKlSne8rVaby0RA",
              description: "Family",
              subDescription: "23 Members",
              subTitle: "Public",
              title: "The Schwartz Clan"
            }
          ],
          title: "Friends & Family"
        },
        {
          items: [
            {
              avatar: "https://cdn.filepicker.io/api/file/n0NURujUSGKfk3qsU8Cp",
              description: "Politics",
              subDescription: "2,567,489 Members",
              subTitle: "Public",
              title: "Conservative Action Network "
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/SoDzfyZuTiqnm6LHbaN8",
              description: "Sports",
              href: "https://scroll-test.d1jelem2fyp0z4.amplifyapp.com",
              subDescription: "126,531 Members",
              subTitle: "Public",
              title: "Bayern Munich Fan Club"
            },
            {
              avatar: "https://cdn.filestackcontent.com/syxgonfyTZmsrlau52RI",
              description: "Sports",
              subDescription: "24,233 Members",
              subTitle: "Public",
              title: "HGTV Renovation Network"
            },
            {
              avatar: "https://cdn.filestackcontent.com/XncW8oCPQO6utrmzyqkY",
              description: "Entertainment",
              subDescription: "2355 Members",
              subTitle: "Public",
              title: "HBO Fan Network"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/DiDJFhSXer1HPR1w8uBA",
              description: "Business",
              subDescription: "143,817 Members",
              subTitle: "Public",
              title: "Metro Detroit Business Community"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/sSdMWFlwRFe7BfbjnWdA",
              description: "Education",
              subDescription: "23,556 Members",
              subTitle: "Public",
              title: "Princeton University Network"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/4CxhGb5QEKLUoGC7d4fR",
              description: "Education",
              subDescription: "2355 Members",
              subTitle: "Public",
              title: "Shamrock Alumni Network"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/ODUYSSd5QmGQROAT143E",
              description: "Entertainment",
              subDescription: "37,359 Members",
              subTitle: "Public",
              title: "The Beermongers"
            }
          ],
          title: "Other Communities"
        },
        {
          items: [
            {
              avatar: "https://cdn.filepicker.io/api/file/pkEXUbFmTd2azM64GxLw",
              avatarMedium:
                "https://cdn.filepicker.io/api/file/5iwntSqQUmudQCqEMVJg",
              description: "Group",
              subDescription: "564,908 Members",
              subTitle: "Private",
              title: "American Conservative Union"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/aLaPSW2pQu2cHitVvJx2",
              avatarMedium:
                "https://cdn.filepicker.io/api/file/eCxSg8BbQE2Zhts1RguH",
              description: "Group",
              subDescription: "2,112,736 Members",
              subTitle: "Subscription",
              title: "Fox Nation"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/RfuFi9MiRUCoIMHk2PYU",
              avatarMedium:
                "https://cdn.filepicker.io/api/file/z4fZV2yeRGGGt9C5qHm4",
              description: "Group",
              subDescription: "425,965 Members",
              subTitle: "Private",
              title: "Michigan GOP"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/yxskaKf8SFmhx2LF3ag9",
              avatarMedium:
                "https://cdn.filepicker.io/api/file/aFM3VvEjQLCWZOJgr2Ne",
              description: "Group",
              subDescription: "12,908 Members",
              subTitle: "Public",
              title: "Trumpalufagans"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/ZtjFp7lREq6g3j0eWyQg",
              avatarMedium:
                "https://cdn.filepicker.io/api/file/taG0wIOcRmOFDqU5AQ0D",
              description: "Group",
              subDescription: "12,309 Members",
              subTitle: "Public",
              title: "Young Republicans"
            }
          ],
          parent: {}
        },
        {
          alternateTitle: "Active Conversations",
          items: [
            {
              avatar: "https://cdn.filepicker.io/api/file/B4priGS9QNmv7WZFj9TT",
              conversation: {
                title: "Do you remember ?"
              },
              description: "Pasadena, CA",
              parent: {},
              subDescription: "Friend",
              title: "Raul Argenta",
              titleInitial: "A"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/0CNdePerTDKWp32wai9V",
              description: "Los Angeles, CA",
              parent: {},
              subDescription: "Friend",
              title: "Jessica Atwood"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/Xx4sDdqrQnKTC9R0YnCM",
              activityCount: 7,
              conversation: {
                title: "Do you remember ?"
              },
              description: "Northville, MI",
              hasActivity: true,
              parent: {},
              subDescription: "Cousin",
              title: "Crystal Bennett"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/W37IhhbEQv231gUbudKk",
              description: "Targu Mures, Romania",
              parent: {},
              subDescription: "Work Colleague",
              title: "Martin Bishop"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/6aGzVNKqS1eIurehtc2Z",
              activityCount: 4,
              description: "Syracuse, NY",
              hasActivity: true,
              parent: {},
              subDescription: "Friend",
              title: "Emmett Campbell"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/xIAxqvCDQxeEyIH1JuZB",
              description: "London, England",
              parent: {},
              subDescription: "Neighbor",
              title: "Sandra Carson"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/luCGAw2tTlqNuNM03uNe",
              activityCount: 3,
              description: "Hammtramack, MI",
              hasActivity: true,
              parent: {},
              subDescription: "Cousin",
              title: "Allen Czsnerik"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/iX3L4YmlSig47oC8EkxZ",
              description: "Vaduz, Lichtensteim",
              parent: {},
              subDescription: "Work Colleague",
              title: "Vlade Escher"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/Bg0fLv6S1O5seW4wW9hF",
              description: "Lyon, France",
              parent: {},
              subDescription: "Friend",
              title: "Alain Gervais"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/0RGHP6YRcfjPDO51rcWg",
              description: "Cork, Ireland",
              parent: {},
              subDescription: "Uncle",
              title: "Barry Barden O'Malley"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/y5OfaTr4S8Gz2N2EBdHQ",
              conversation: {
                outgoing_request: [
                  {
                    date: "Today",
                    items: [
                      {
                        text:
                          "Hi I would like to connect in this network we met at Arby's"
                      }
                    ],
                    parent: {},
                    time: "6:34 pm"
                  }
                ],
                title: "Do you remember ?"
              },
              description: "Augsburg, Germany",
              parent: {},
              subDescription: "Customer",
              title: "Fritz Reuss"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/Hvkt5E8SqiKgh9NKJWZ0",
              conversation: {
                outgoing_request: [
                  {
                    date: "Today",
                    items: [
                      {
                        text:
                          "What's happeing? Ready to connect?"
                      }
                    ],
                    parent: {},
                    time: "6:34 pm"
                  }
                ],
                title: "New Connection"
              },
              description: "Dayton, OH",
              parent: {},
              subDescription: "Friend",
              title: "Alvaro Shanks"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/hLiNLiTQIKyqDKEXGIFN",
              conversation: {
                incoming_request: [
                  {
                    date: "Today",
                    items: [
                      {
                        text:
                          "Hi I would like to connect in this network we met at Arby's"
                      }
                    ],
                    time: "6:34 pm"
                  }
                ],
                title: "Do you remember ?"
              },
              description: "Athens, GA",
              parent: {},
              subDescription: "Brother-in-Law",
              title: "Vernon Steele"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/yL0X5X32TO6iKoWfCc8o",
              description: "Salburg, Austria",
              parent: {},
              subDescription: "Friend",
              title: "Irving vonTrapp"
            },
            {
              avatar: "https://cdn.filepicker.io/api/file/vEl6MwGaSZTybgYRE55g",
              description: "Eugene, OR",
              parent: {},
              subDescription: "Cousin",
              title: "Jeff Schwartz"
            }
          ],
          parent: {}
        },
        {
          alternateTitle: "Start New Conversation",
          items: [],
          parent: {}
        }
      ]
    },
    user: {
      avatar: "https://cdn.filestackcontent.com/V7YJCieTuOmxwmBl7JwM",
      communicationFeatures: {
        addonAfter: {
          avatar: "https://cdn.filepicker.io/api/file/1Wj5V8OGRLatJK5wq82J"
        },
        items: [
/*           {
            addonBefore: {
              avatar: "https://cdn.filepicker.io/api/file/iYdhGeNWTM6Hikb4LKot"
            },
            title: "Connect in this Network"
          }, */
          {
            addonBefore: {
              avatar: "https://cdn.filepicker.io/api/file/765er8KJTN6W0jezNpWD"
            },
            title: "Send an external Email"
          },
          {
            addonBefore: {
              avatar: "https://cdn.filepicker.io/api/file/iFMDguQRSiyJ1NWdq9ah"
            },
            handleHiddenContent: true,
            title: "Vowtch for Someone"
          },
          {
            addonBefore: {
              avatar: "https://cdn.filepicker.io/api/file/IxODj76bRa2aIx3pv2ol"
            },
            handleHiddenContent: true,
            title: "Vowtch for Something"
          }
        ],
        updates: 4
      },
      content: [
        {
          author: {
            avatar: "https://cdn.filepicker.io/api/file/vEl6MwGaSZTybgYRE55g",
            title: "Jeff Schwartz"
          },
          content_type: "News Article",
          date_published: "Feb 24, 2019",
          title: {
            avatar: "https://cdn.filepicker.io/api/file/93uGgHm4Q1Orq6zyllL5",
            title: "The Flying Dutchman"
          }
        },
        {
          author: {
            avatar: "https://cdn.filepicker.io/api/file/vEl6MwGaSZTybgYRE55g",
            title: "Jeff Schwartz"
          },
          content_type: "Video",
          date_published: "Feb 18, 2019",
          title: {
            avatar: "https://cdn.filepicker.io/api/file/OhaQylr7SxOWGirGO1ft",
            title: "Silicon Valley Teaser (Season 6)"
          }
        },
        {
          author: {
            avatar: "https://cdn.filepicker.io/api/file/vEl6MwGaSZTybgYRE55g",
            title: "Jeff Schwartz"
          },
          content_type: "News Article",
          date_published: "Feb 17, 2019",
          title: {
            avatar: "https://cdn.filepicker.io/api/file/gYfszU3oRXSL0mCITjXt",
            title: "Hot Seat for the Iron Throne"
          }
        },
        {
          author: {
            avatar: "https://cdn.filepicker.io/api/file/vEl6MwGaSZTybgYRE55g",
            title: "Jeff Schwartz"
          },
          content_type: "Featured Analysis",
          date_published: "Feb 11, 2019",
          title: {
            avatar: "https://cdn.filepicker.io/api/file/vWPrueIQGaqxGT0AZXAi",
            title: "Westworld Dystopian Eros"
          }
        },
        {
          author: {
            avatar: "https://cdn.filepicker.io/api/file/vEl6MwGaSZTybgYRE55g",
            title: "Jeff Schwartz"
          },
          content_type: "Discussion",
          date_published: "Jan 30, 2019",
          title: {
            avatar: "https://cdn.filepicker.io/api/file/NQMTHRRJS7qq4EYPwxTA",
            title: "Dead On Arrival"
          }
        },
        {
          author: {
            avatar: "https://cdn.filepicker.io/api/file/vEl6MwGaSZTybgYRE55g",
            title: "Jeff Schwartz"
          },
          content_type: "News Article",
          date_published: "Jan 29, 2019",
          title: {
            avatar: "https://cdn.filepicker.io/api/file/Exf8nqNfTGKy7OKg23XX",
            title: "High Noon at the Gem Saloon"
          }
        },
        {
          author: {
            avatar: "https://cdn.filepicker.io/api/file/vEl6MwGaSZTybgYRE55g",
            title: "Jeff Schwartz"
          },
          content_type: "News Article",
          date_published: "Jan 6, 2019",
          title: {
            avatar: "https://cdn.filepicker.io/api/file/HzS3ME4iS8W6QGa2DVFS",
            title: "Well Hung in Michigan"
          }
        },
        {
          author: {
            avatar: "https://cdn.filepicker.io/api/file/vEl6MwGaSZTybgYRE55g",
            title: "Jeff Schwartz"
          },
          content_type: "News Article",
          date_published: "Dec 27, 2018",
          title: {
            avatar: "https://cdn.filepicker.io/api/file/GOZn1MyIQVKH7im8Qnzh",
            title: "Larry Gets Foisted"
          }
        },
        {
          author: {
            avatar: "https://cdn.filepicker.io/api/file/vEl6MwGaSZTybgYRE55g",
            title: "Jeff Schwartz"
          },
          content_type: "Video",
          date_published: "Dec 23, 2018",
          title: {
            avatar: "https://cdn.filepicker.io/api/file/cjNx9XWhT3q0KkGM6VDv",
            title: "How Big is You Love"
          }
        },
        {
          author: {
            avatar: "https://cdn.filepicker.io/api/file/vEl6MwGaSZTybgYRE55g",
            title: "Jeff Schwartz"
          },
          content_type: "News Article",
          date_published: "Dec 7, 2018",
          title: {
            avatar: "https://cdn.filepicker.io/api/file/ZsHz89RlRGGptSP3I2CA",
            title: "Ari Strikes Gold"
          }
        }
      ],
      contentTopics: [
        {
          title: "Active Feed"
        },
        {
          title: "Archived"
        },
        {
          title: "Historical"
        },
        {
          title: "Suspended"
        },
        {
          title: "Alphabetic"
        }
      ],
      conversation: [
        {
          date: "Today",
          items: [
            {
              text: "We met at Hollywood Boulevard"
            },
            {
              text: "Do you remember ?"
            }
          ],
          time: "3:50 pm"
        },
        {
          items: [
            {
              text:
                "Jessica...when are we going to see the latest rendition of the play ?"
            }
          ],
          parent: {},
          time: "6:34 pm"
        },
        {
          date: "Today",
          items: [
            {
              text: "We met at Hollywood Boulevard"
            },
            {
              text: "Do you remember ?"
            }
          ],
          time: "3:50 pm"
        },
        {
          items: [
            {
              text:
                "Jessica...when are we going to see the latest rendition of the play ?"
            }
          ],
          parent: {},
          time: "6:34 pm"
        },
        {
          date: "Today",
          items: [
            {
              text: "We met at Hollywood Boulevard"
            },
            {
              text: "Do you remember ?"
            }
          ],
          time: "3:50 pm"
        },
        {
          items: [
            {
              text:
                "Jessica...when are we going to see the latest rendition of the play ?"
            }
          ],
          parent: {},
          time: "6:34 pm"
        }
      ],
      description: "Detroit, MI",
      distributionTopics: [
        {
          title: "Distribution Lists"
        },
        {
          title: "Shared Groups"
        },
        {
          title: "Personal Groups"
        },
        {
          title: "Subscription Lists"
        },
        {
          title: "Other Lists"
        }
      ],
      full_description:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, \
      totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo \
      Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui \
      ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci ?",
      libraryTopics: [
        {
          title: "Images"
        },
        {
          title: "Videos"
        },
        {
          title: "Audio Files"
        },
        {
          title: "Documents"
        }
      ],
      loader: {
        items: [
          {
            title: "Show More"
          }
        ]
      },
      networkFeatures: {
        items: [
          {
            addonBefore: {
              avatar: "https://cdn.filestackcontent.com/j9NCAVhbSgKqtzo2z7fL"
            },
            title: "This is a true identity community"
          }
        ],
        updates: 4
      },
      publishingContentTypes: {
        addonAfter: {
          avatar: "https://cdn.filepicker.io/api/file/uRxuQ08TuGFdFowBZWjQ"
        },
        avatar: "https://cdn.filestackcontent.com/bkX5hbnOTQOb58gIcNYF",
        items: [
          {
            addonBefore: {
              avatar: "https://cdn.filestackcontent.com/tWszdJYrTaei07V6FCyQ"
            },
            avatar: "https://cdn.filestackcontent.com/jKBivwrpS6wR7xuFWbuN",
            href: "/app/publishing-post",
            subject: true,
            title: "Post"
          },
          {
            addonBefore: {
              avatar: "https://cdn.filestackcontent.com/ImqTbyh0RIqB3BaZdZ8I"
            },
            avatar: " https://cdn.filestackcontent.com/Ft16LCYYSeO4YhqNOB5a",
            // href: "/app/publishing-content-types",
            subject: true,
            title: "News Article"
          },
          {
            addonBefore: {
              avatar: "https://cdn.filestackcontent.com/wlVnsXRDTE6QsSNGx5Wq"
            },
            avatar: "https://cdn.filestackcontent.com/6qqMOayuTQiqWZJoZcLD",
            // href: "/app//publishing-library",
            title: "Event Notice"
          },
          {
            addonBefore: {
              avatar: "https://cdn.filestackcontent.com/vbtiT58PROCXno60YdM3"
            },
            avatar: "https://cdn.filestackcontent.com/M5Nj1xPxSi2cMbSdg1hK",
            // href: "/app//publishing-distribution",
            title: "Video"
          },
          {
            addonBefore: {
              avatar: "https://cdn.filestackcontent.com/wlVnsXRDTE6QsSNGx5Wq"
            },
            avatar: "https://cdn.filestackcontent.com/NoYMJYiaQteua4mqnXaw",
            // href: "/app//publishing-library",
            title: "Audiocast"
          },
          {
            addonBefore: {
              avatar: "https://cdn.filestackcontent.com/v5K9BJoFTACK5xgdok7N"
            },
            avatar: "https://cdn.filestackcontent.com/iA129G1dRvWF82ULV88Q",
            // href: "/app/publishing-management",
            title: "Photo Presentation"
          }
        ]
      },
      publishingFeatures: {
        addonAfter: {
          avatar: "https://cdn.filepicker.io/api/file/uRxuQ08TuGFdFowBZWjQ"
        },
        avatar: "https://cdn.filestackcontent.com/bkX5hbnOTQOb58gIcNYF",
        items: [
          {
            addonBefore: {
              avatar: "https://cdn.filestackcontent.com/ImqTbyh0RIqB3BaZdZ8I"
            },
            avatar: "https://cdn.filestackcontent.com/tWszdJYrTaei07V6FCyQ",
            href: "/app/publishing-content-types",
            subject: true,
            title: "Produce Content"
          },
          {
            addonBefore: {
              avatar: "https://cdn.filestackcontent.com/v5K9BJoFTACK5xgdok7N"
            },
            avatar: "https://cdn.filestackcontent.com/v5K9BJoFTACK5xgdok7N",
            href: "/app/publishing-management",
            title: "Manage Content"
          },
          {
            addonBefore: {
              avatar: "https://cdn.filestackcontent.com/tWszdJYrTaei07V6FCyQ"
            },
            avatar: "https://cdn.filestackcontent.com/qqibt6sKQGlVZ0CA1Re2",
            // href: "/app/publishing-article",
            subject: true,
            title: "Manage Folios"
          },
          {
            addonBefore: {
              avatar: "https://cdn.filestackcontent.com/vbtiT58PROCXno60YdM3"
            },
            avatar: "https://cdn.filestackcontent.com/vbtiT58PROCXno60YdM3",
            href: "/app//publishing-distribution",
            title: "Manage Distribution"
          },
          {
            addonBefore: {
              avatar: "https://cdn.filestackcontent.com/wlVnsXRDTE6QsSNGx5Wq"
            },
            avatar: "https://cdn.filestackcontent.com/wlVnsXRDTE6QsSNGx5Wq",
            href: "/app//publishing-library",
            title: "Go To Library"
          }
        ]
      },
      query_results: {
        columns: 3,
        count: 25
      },
      query_topics: [
        {
          title: "Beta Testers"
        }
      ],
      parent: {},
      saved_queries: [
        {
          activityCount: 27,
          count: 235,
          hasActivity: true,
          title: "Recently Added"
        },
        {
          count: 147,
          title: "Most Popular Communitites"
        },
        {
          activityCount: 13,
          count: 84,
          hasActivity: true,
          title: "Football Communities"
        }
      ],
      shared_contacts: 3,
      shared_interests: 13,
      short_description:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, \
      totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo ",
      subDescription: "",
      title: "",
      topics_history: [
        {
          count: 235,
          title: "#Mueller Report"
        },
        {
          count: 837,
          title: "#Ocasio Cortez"
        },
        {
          count: 114,
          title: "#Limbaugh"
        },
        {
          count: 137,
          title: "#Ted Cruz"
        }
      ],
      trendTopics: [
        {
          title: "Border Wall"
        },
        {
          title: "Economy"
        },
        {
          title: "Kim Jong Un"
        },
        {
          title: "Mark Levin"
        },
        {
          title: "Sean Hannity"
        }
      ],
      useStateWithLocalStorageKey: "keycloakUser"
    }
  },
  events: {
    change: "change"
  },
  favicon: "https://cdn.filepicker.io/api/file/RUq3qshnQiyzPcvRcA56",
  grid: {
    alternateFormCoverJustify: "normal",
    cardInnerWidgetBlockCardJustify: "inherit",
    cardInnerWidgetBlockCardJustifyAlternate: "space-around",
    carouselJustify: "safe",
    carouselJustifyAlternate: "unsafe",
    classieContentTitleJustify: "unset",
    classieContentTitleJustifyAlternate: "unsafe",
    formCoverJustify: "initial",
    formCoverJustifyAlternate: "inherit",
    formFooterJustify: "safe",
    formFooterJustifyAlternate: "unsafe",
    gridAddonAfterTreshold: 6,
    gridAddonBeforeTreshold: 3,
    gridSpan: 24,
    gridSpanDoubleTreshold: 2,
    gridSpanTreshold: 1,
    mainHeaderJustify: "space-between",
    menuBadgeJustify: "start",
    menuHeaderJustify: "left",
    menuHeaderSecondaryJustify: "space-between",
    menuJustify: "left",
    menuJustifyAlternate: "inherit",
    menuSecondaryJustify: "space-between"
  },
  href: "https://vowtch-alpha.d291gxazrx2ik7.amplifyapp.com",
  // href: "/",
  hrefAlternate: "https://vowtch-alpha.d1xbpmguxt6ast.amplifyapp.com",
  menu: {
    alternateItem: {
      carouselContent: "5d135ff8fb6fc00e79b09c00",
      dynamicItems: true,
      hasAction: true,
      hasParent: true,
      level: 1,
      title: "Where do you want to go ?"
    },
    fifthPartyItem:  {
      buttonTitle: "Search By Topic",
      extraButtonTitle: "Go",
      href: "/app/search-results",
      items: [

      ],
      options: [

      ],
      title: "What are you looking for ?",
    },
    fourthPartyItem:  {
      buttonTitle: "Upload Media",
      items: [
        {
          href: "/app/publishing-post",
          title: "Make a Post"
        },
        {
          href: "/app/publishing-content-types",
          title: "Produce Content"
        },
        {
          href: "/app/publishing-management",
          title: "Manage Content"
        },
        {
          href: "/app//publishing-distribution",
          title: "Manage Distribution"
        },
        {
          href: "/app//publishing-library",
          title: "Go To Library"
        }
      ],
      title: "What do you want to do ?"
    },
    header: [
      // {
      //   menuShown: true,
      //   title: "Menu"
      // },
      // {
      //   thirdPartyMenuShown: true,
      //   title: "Conversations"
      // },
      // {
      //   fourthPartyMenuShown: true,
      //   title: "The Studio"
      // },
      // {
      //   alternateMenuShown: true,
      //   title: "Navigator"
      // },
      // {
      //   fifthPartyMenuShown: true,
      //   title: "Search"
      // }
    ],
    headerAlternate: [

    ],
    headerDropdownPlacement: "bottomCenter",
    items: [
      {
        carouselContent: "5d135ff8fb6fc00e79b09c99",
        href: "/",
        title: "News Channel"
      },
      {
        activityCount : 5,
        alternateItem : {
          hasAvatar : true,
          hidden : true,
          items : [
            {
              activityCount : 55,
              hasActivity : true,
              title : "Channel"
            },
            {
              title : "History"
            },
            {
              href : "/app/users-profile",
              title : "About"
            },
            {
              hasParent : true,
              href : "/app/users-profile",
              title : "Contact"
            }
          ]
        },
        dynamicItems : true,
        form : true,
        hasActivity : true,
        level : 1,
        title : "Connections"
      },
      {
        activityCount: 5,
        carouselContent: "5d135ff8fb6fc00e79b09c00",
        hasActivity: true,
        href: "/app/global-directory",
        level: 1,
        title: "Directory"
      },
      {
        activityCount: 5,
        hasActivity: true,
        items: [
          {
            title: "Tracking & Reports "
          },
          {
            title: "Account Management"
          }
        ],
        level: 1,
        title: "Platform Activities"
      },
      {
        hasAvatar: true,
        hidden: true,
        items: [
          {
            activityCount: 5,
            hasActivity: true,
            title: "Channel"
          },
          {
            title: "History"
          },
          {
            href: "/app/users-profile",
            title: "About"
          },
          {
            href: "/app/users-contact",
            title: "Contact"
          },
          {
            hasAvatar: true,
            items: [
              {
                title: "Basic Details"
              },
              {
                title: "Web Presence"
              },
              {
                title: "Skills & Interests"
              },
              {
                title: "Contact Details"
              }
            ],
            level: 1,
            title: "Administration"
          }
        ],
        level: 1,
        title: "Personal Folio"
      }
    ],
    thirdPartyItem: {
      dynamicItems: true,
      hasAction: true,
      hasParent: true,
      level: 1,
      options: [
        {
          defaultValue: true,
          title: "Incoming (For You)",
          value: "incomingRequests"
        },
        {
          title: "Outgoing (From You)",
          value: "outgoingRequests"
        }
      ],
      secondaryOptions: [
        {
          avatar: "https://cdn.filestackcontent.com/OpvZUuDLTC6u1CysmIWM",
          count: 5,
          title: "Outstanding Invitations (From You)",
          value: "incomingRequests",
        },
        {
          avatar: "https://cdn.filestackcontent.com/Yb8fE5mGRKyx76uILyfM",
          count: 5,
          title: "Outgoing Connection Requests (From You)",
          value: "outgoingRequests"
        },
        {
          avatar: "https://cdn.filestackcontent.com/7wwf8TG1TkWAJgZgvHBs",
          count: 5,
          title: "Incoming Connection Requests (For You)",
          value: "incomingRequests"
        },
        {
          avatar: "https://cdn.filestackcontent.com/w2x77A2pRdimAPKGqSzq",
          count: 5,
          title: "New Connections",
          value: "incomingRequests"
        }
      ],
      title: "Select Participants"
    },
    title: "Where do you want to go?",
    threshold: 2
  },
  progress: {
    progressInterval: 500,
    progressMaximumPercent: 100,
    progressPercent: 10,
    progressWidth: 32
  },
  rcQueueAnim: {
    delay: [0, 1000],
    duration: [500, 5000],
    interval: [500, 1000]
  },
  regions: {
    hasAvatar: false,
    items: [
      {
        items: [
          {
            avatar: "https://cdn.filestackcontent.com/z1BHwdIwRHm4GZaD3zWW",
            hasAction: true,
            hasAvatar: true,
            description: "",
            parent: {},
            subDescription: "",
            subTitle: "Algeria is a regional and middle power. It supplies large amounts of natural gas to Europe, and energy exports are the backbone of the economy. According to OPEC Algeria has the 16th largest oil reserves in the world and the second largest in Africa, while it has the 9th largest reserves of natural gas. Sonatrach, the national oil company, is the largest company in Africa. Algeria has one of the largest militaries in Africa and the largest defence budget on the continent; most of Algeria's weapons are imported from Russia, with whom they are a close ally.[13][14] Algeria is a member of the African Union, the Arab League, OPEC, the United Nations and is a founding member of the Arab Maghreb Union.",
            title: "Algeria"
          },
          {
            hasAction: true,
            title: "Angola"
          },
          {
            hasAction: true,
            title: "Cameroon"
          },
          {
            hasAction: true,
            title: "Nigeria"
          },
          {
            hasAction: true,
            title: "Senegal"
          },
          {
            hasAction: true,
            title: "Tunisia"
          },
          {
            title: ""
          }
        ],
        level: 1,
        title: "Africa"
      },
      {
        items: [
          {
            title: "Argentina"
          },
          {
            title: "Brasil"
          },
          {
            title: "Chile"
          },
          {
            title: "Colombia"
          },
          {
            title: "Paraguay"
          },
          {
            title: "Uruguay"
          },
          {
            title: ""
          }
        ],
        level: 1,
        title: "Americas"
      },
      {
        items: [
          {
            title: "Australia"
          },
          {
            title: "Brunei"
          },
          {
            title: "Cambodia"
          },
          {
            title: "China"
          },
          {
            title: "India"
          },
          {
            title: "Japan"
          },
          {
            title: ""
          }
        ],
        level: 1,
        title: "Asia Pacific"
      },
      {
        items: [
          {
            title: "Belgium"
          },
          {
            title: "England"
          },
          {
            title: "France"
          },
          {
            avatar: "https://cdn.filestackcontent.com/maaAimTySj6CmM94XzHJ",
            hasAction: true,
            hasAvatar: true,
            description: "",
            parent: {},
            subDescription: "",
            subTitle: "Germany includes 16 constituent states, covers an area of 357,386 square kilometres (137,988 sq mi),[7] and has a largely temperate seasonal climate. With 83 million inhabitants, it is the second most populous state of Europe after Russia, the most populous state lying entirely in Europe, as well as the most populous member state of the European Union.",
            title: "Germany"
          },
          {
            title: "Italy"
          },
          {
            title: "Spain"
          },
          {
            title: ""
          }
        ],
        level: 1,
        title: "Europe"
      },
      {
        items: [
          {
            title: "Egypt"
          },
          {
            title: "Iran"
          },
          {
            title: "Iraq"
          },
          {
            title: "Israel"
          },
          {
            title: "Syria"
          },
          {
            title: "Yemen"
          },
          {
            title: ""
          }
        ],
        level: 1,
        title: "Middle East"
      },
      {
        title: ""
      }
    ],
    level: 0,
    title: "Regions"
  },
  regularExpressions: {
    regularExpressionsTagsField: /[A-Z]/g,
    regularExpressionsTagsFieldAlternate: /(?=[A-Z])/
  },
  siteDescription: "Vowtch",
  siteTitle: "Vowtch",
  siteUrl: "",
  table: {
    pagination: false,
    size: "small"
  },
  tagsonomy: {
    basetagsonomy: {
      listing: [
        {
          items: [
            {
              name: "Arts & Entertainment",
              title: "Arts & Entertainment"
            },
            {
              name: "Business & Economics",
              title: "Business & Economics"
            },
            {
              name: "Regions",
              title: "Region"
            },
            {
              name: "Culture & Lifestyle",
              title: "Culture & Lifestyle"
            },
            {
              name: "Education",
              title: "Education"
            },
            {
              name: "Government, Law & Politics",
              title: "Government, Law & Politics"
            },
            {
              name: "Nature & Wildlife",
              title: "Nature & Wildlife"
            },
            {
              name: "Science & Technology",
              title: "Science & Technology"
            },
            {
              name: "Sports & Leisure",
              title: "Sports & Leisure"
            },
            {
              name: "Other Topics",
              title: "Other Topics"
            },
            {
              title: ""
            }
          ],
          hasAction: true,
          title: "Browse Topics"
        }
      ],
      name: "Topic",
      title: "Browse Topics"
    },
    tagDatatransfomation: [
      {
        defaultField: "avatar",
        field: "avatar"
      },
      {
        defaultField: "description",
        field: "type",
      },
      {
        defaultField: "hasAvatar",
        field: "hasAvatar"
      },
      {
        defaultField: "parent",
        field: "parent"
      },
      {
        defaultField: "subDescription",
        field: "description"
      },
      {
        defaultField: "subSubDescription",
        field: "wikipediaUrl"
      },
      {
        defaultField: "subTitle",
        extraMarkerField: true,
        field: "subTitle"
      },
      {
        defaultField: "title",
        field: "title",
        markerField: true
      }
    ],
    text: {
      extraMarkerFieldSlugText: "Read More"
    }
  },
  text: {
    activityResultAlternateBadgeText: "Updates",
    activityResultAlternateSeparatorText: " - ",
    activityResultAlternateSlugText: "People",
    activityResultAlternateText: "Total Results",
    activityResultBadgeText: "New",
    activityResultFilterText: "Ordered by size",
    activityResultSeparatorText: " ",
    activityResultSlugText: "Groups",
    activityResultText: "Results",
    activityResultSecondarySeparatorText: ", ",
    activityResultThirdPartySeparatorText: "",
    activitySeparatorText: "+",
    alternateActivityResultAlternateSeparatorText: "-",
    alternateActivityResultThirdPartySeparatorText: "#",
    alternateInnerWidgetInnerWidgetFormTitle: "Community Summary",
    alternateInnerWidgetInnerWidgetFormValueKey: "alternateTopic",
    alternateInnerWidgetInnerWidgetFormValueKeyAlternate: "customTopic",
    alternateInnerWidgetInnerWidgetFormValueKeyThirdParty: "customTopicyAlternate",
    bottomCenterDraggerSlugText: "Click Here",
    bottomLeftAlternateSlugText: "Go To Vowtch Central",
    bottomLeftDraggerSlugText: "or",
    bottomLeftSecondarySlugText: "Link Not Available",
    bottomLeftThirdPartySlugText: "Type a Message",
    bottomRightDraggerSlugText: "to browse your files",
    bottomRightHeaderSlugText: "Edit this profile",
    bottomRightThirdPartySlugText: "Invitations & Connections",
    classieContentBadgeSeparatorText: "for",
    classieContentBadgeText: [],
    classieContentThirdPartySlugText: "Comments",
    classieContentThirdPartySlugTextAlternate: "No Comments",
    footerMainThirdPartyMenuLeftButtonSlugText: "Cancel",
    footerMainThirdPartyMenuRightButtonSlugText: "Send Request",
    footerMainThirdPartyMenuRightButtonSlugTextAlternate: "Next",
    formDrawerSlugText: [
      "memorial"
    ],
    inlineInformationAlternateSlugText: "Last Update Aug 22, 2018",
    inlineInformationSlugText: "RE: The Convention",
    innerWidgetAlternateSlugText: "Show More",
    innerWidgetInnerWidgetFormTitle: "User Summary",
    innerWidgetInnerWidgetFormAlternateTitle: "Summary",
    innerWidgetInnerWidgetFormThirdPartyTitle: "Content Summary",
    innerWidgetInnerWidgetFormValueKey: "topic",
    innerWidgetSlugText: "Show Less",
    leftSlugText: "Powered By",
    linkTarget: "_blank",
    linkText: "/app/networks",
    mainThirdPartyMenuThirdPartySlugText: "What do you want to do?",
    menuSeparatorText: "|",
    messageLeftText: 'Yes',
    messageRightText: 'No',
    messageText: [
      {
        title: 'Your alias identity in this community has not been connected to this person to date. Are you sure you want to connect ?'
      },
      {
        title: 'Confirm Connection'
      }
    ],
    quotesText: '"',
    rightSlugText: "Vowtch Networks",
    topCenterSlugAlternateThirdPartyText: "The Studio",
    topCenterSlugThirdPartyAlternateText: "Connection Request",
    topCenterSlugThirdPartyFourthdPartyText: "Invite this contact to Shared Groups or Events",
    topCenterSlugThirdPartyFourthdPartyAlternateText: "Connect in other Communities",
    topCenterSlugThirdPartyFourthdPartyThirdPartyText: "This person is a member of the following communities. Select items below to connect in other communities",
    topCenterSlugThirdPartyFourthdPartyThirdPartyAlternateText: "How do you know this Person?",
    topCenterSlugThirdPartyFourthdPartyThirdPartyThirdPartyAlternateText: [
      {
        title: "Selecting this option indicates that you would like to automatically connect with this person in any communities (at present and in the future) where you are both members."
      },
      {
        title: 'Important Note:'
      },
      {
        title: 'This option will only connect you automatically in cases where both identities that the members have set-up for the given community are already connected elsewhere. In cases where both identities have not been connected to date, you will be asked to confirm the connection manually.'
      },
      {
        title: 'In the future, you will be simply be sent a new connection request for any mutual community where both identities have not been previously connected.'
      }
    ],
    topCenterSlugThirdPartyFourthdPartyThirdPartyThirdPartyThirdPartyAlternateText: [
      {
        title: "Personal Groups are groups that you create to organize your relationships in the way you see the world. Unlike “Shared Groups” which other people can join, nobody else on Vowtch will know about or will see your Personal Groups."
      },
      {
        title: ''
      },
      {
        title: 'Vowtch creates a Folio for each of your Personal Groups enabling you to easily navigate to content produced by people you designate as its members. Personal groups also enable you to easily target select content by assigning the name of that Personal Group to the distribution listing when creating content.'
      }
    ],
    topCenterSlugThirdPartyFourthdPartyThirdPartyThirdPartyText: "Always connect me with this Person",
    topCenterSlugThirdPartyFourthdPartyThirdPartyThirdPartyThirdPartyText: "Add this contact to your Personal Groups",
    topCenterSlugThirdPartyFourthdPartyThirdPartyThirdPartyThirdPartyThirdPartyText: "What is a Personal Group?",
    topCenterSlugThirdPartyText: "General Conversation",
    topCenterSlugThirdPartyThirdPartyText: "Topic Definition",
    topDraggerSlugText: "Drop image here to upload...",
    topLeftSlugAlternateText: "Menu",
    topLeftSlugInnerWidgetInnerWidgetForm: "Vowtch Networks",
    topLeftSlugFourthPartyText: "The Studio",
    topLeftSlugFifthPartyText: "Search",
    topLeftSlugSeparatorText: ".",
    topLeftSlugSeparatorTextAlternate: ". ",
    topLeftSlugText: "Vowtch",
    topLeftSlugThirdPartyText: "Vowtch Communicator",
    topLeftSlugThirdPartyThirdPartyText: "Vowtch Tagsonomies",
    topMainAlternateMenuDynamicBodySlugText: "What are you looking for?",
    topMainFifthPartyMenuDynamicBodySlugText: "Enter Search Term Here...",
    topMainFifthPartyMenuSelectFieldPlaceholder: "Apply Search Filters",
    topMainThirdPartyMenuLeftButtonSlugText: "Accept",
    topMainThirdPartyMenuLeftButtonSlugAlternateText: "Re-Send",
    topMainThirdPartyMenuRightButtonSlugText: "Decline",
    topMainThirdPartyMenuRightButtonSlugAlternateText: "Cancel",
    topMainThirdPartyMenuSelectFieldPlaceholder: "All Requests",
    topRightSlugText: "Vowtch Navigator",
    urlSeparatorText: "/",
    widgetText: "Vowtch",
    widgetTextAlternate: "Share",
    widgetTextThirdParty: "More Info"
  },
  tooltipText: {
    bottomLeftTooltipText: "Switch Communities",
    bottomRightTooltipText: "Log Out"
  },
  upload: {
    status: "uploading"
  }
};
